<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
					<a-button class="btn btn-success ml5" icon="reload" @click="onReload()" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="bankingInstitutionsList" rowKey="id">
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record)" />
							<a-button class="ml7" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onToggleRecord(record.id, record.active)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<insertOrUpdateBankingInstitutions :visible="modal.visible" :title="modal.title" :payload="modal.payload" @closeModal="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import insertOrUpdateBankingInstitutions from '@/components/insertOrUpdateBankingInstitutions'

export default {
	name: 'bankingInstitutions',
	components: {
		insertOrUpdateBankingInstitutions,
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Nombre Corto',
					dataIndex: 'short_name',
					align: 'center',
				},
				{
					title: 'Nombre de la Institución Bancaria',
					dataIndex: 'bank_name',
				},
			],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('bankingInstitutions', ['bankingInstitutionsList']),
		spinnerLoaderLabel() {
			return this.$store.state.bankingInstitutions.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.bankingInstitutions.spinnerLoader
		},
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	beforeMount() {
		if (this.isUserAdmin) {
			this.columns.push({
				title: 'Acciones',
				scopedSlots: { customRender: 'action' },
				align: 'right',
			})
		}
	},
	mounted() {
		if (this.bankingInstitutionsList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('bankingInstitutions/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: 'Añadir registro de Institución Bancaria',
				payload: {},
			}
		},
		onEditRecord(record) {
			this.modal = {
				visible: true,
				title: 'Editar registro de Institución Bancaria',
				payload: _.cloneDeep(record),
			}
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (status) {
						this.$store.dispatch('bankingInstitutions/DISABLE', id)
					} else {
						this.$store.dispatch('bankingInstitutions/ENABLE', id)
					}
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
	},
}
</script>